import {useState, useEffect} from "react";
import {NavLink} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLink} from "@fortawesome/free-solid-svg-icons";
import {useIntl} from "react-intl";

import MyDisclaimerModal from "./MyDisclaimerModal";

const MyReferences = ({references}) => {
  // BEGIN

  // Arg check
  if (!references) return;
  if (references.length < 1) return;

  // State
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  // const [linkClicked, setLinkClicked] = useState(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const [linkHref, setLinkHref] = useState(null);

  // Localization
  const intl = useIntl();

  const currentLocale = intl.locale;
  const referencesTitle = intl.formatMessage({id: "app.referencesTitle"});

  // Test data
  // references = [
  //   {
  //     similarity: "0.595152",
  //     id: "0494b5afa6f3ef1de52bc37a879ebc0cff09f38a",
  //     title: "Pega Sales Automation Product Overview",
  //     heading: "Summary",
  //     url: "https://docs.pega.com/bundle/sales-automation-88/page/sales-automation/product-overview/product-overview-intro.html",
  //   },
  //   {
  //     similarity: "0.592845",
  //     id: "99ebb49c689da2efe99cc228593105e16b18e5a9",
  //     title: "Pega Sales Automation Implementation Guide",
  //     heading: "Summary",
  //     url: "https://docs.pega.com/bundle/sales-automation-88/page/sales-automation/sah-overview-con.html",
  //   },
  //   {
  //     similarity: "0.582714",
  //     id: "978d7229cc9215f6840c6ced4b5425e12bc6f145",
  //     title: "Introduction to Pega Sales Automation",
  //     heading: "Summary",
  //     url: "https://docs.pega.com/bundle/sales-automation-88/page/sales-automation/getting-started/intro-con.html",
  //   },
  //   {
  //     similarity: "0.558180",
  //     id: "a21d356b0650498348e0946dcc3eb8429086a829",
  //     title: "Pega Sales Automation mobile application",
  //     heading: "Summary",
  //     url: "https://docs.pega.com/bundle/sales-automation-88/page/customer-service/install-update-adoption/adopt-sa-mobile-app-1.html",
  //   },
  //   {
  //     similarity: "0.548241",
  //     id: "5ad1a71ac66431f3637dd55cd5c43afa3ab87829",
  //     title: "Artificial intelligence capabilities",
  //     heading: "Summary",
  //     url: "https://docs.pega.com/bundle/sales-automation-88/page/sales-automation/ai/ai.html",
  //   },
  // ];

  // ☀️ - excellent match
  // 🌤️ - good match
  // ⛅️ - fair match
  // ☁️ - poor match
  // 🌩️ - don’t go there

  // https://symbl.cc/en/emoji/travel-and-places/sky-and-weather/
  const similarityIcon = (similarity) => {
    // Excellent match
    // let icon = "☀"; // "🌕"; U+1FA90
    let icon = "🌕"; //
    // Good match
    if (similarity < 0.9) {
      // icon = "⛅"; // U+26C5
      // icon = "🌔";
      icon = "🌖";
    }
    // Fair match
    if (similarity < 0.85) {
      // icon = "🌥"; // U+1F325
      // icon = "🌓";
      icon = "🌗";
    }
    // Poor match
    if (similarity < 0.8) {
      // icon = "☁"; // U+2601
      // icon = "🌒";
      icon = "🌘";
    }
    // Don't go there
    if (similarity < 0.75) {
      // icon = "🌩"; // U+1F329
      icon = "🌑";
    }
    // console.log(icon + " - " + similarity);
    return icon;
  };

  const handleClose = () => {
    // Close the modal
    setShowDisclaimerModal(false);

    // Perform additional actions here
    // console.log("Modal close");

    // Open the href after the modal is closed
    window.open(linkHref, "_blank");
  };

  const handleShow = (href, event) => {
    // console.log("handleShow");
    // console.log(href)
    // console.log(event)

    // If the current locale is not "en"
    if (currentLocale != "en") {
      // If user did NOT indicate do not show again
      if (!doNotShowAgain) {
        // Prevent default navigation event
        event.preventDefault();

        // Preserve the href value
        setLinkHref(href);

        // Open the modal
        setShowDisclaimerModal(true);

        // Update the state to indicate the NavLink has been clicked
        // setLinkClicked(true);

        // console.log(`The href is: ${href}`);
      } else {
        // console.log("NavLink was already clicked before.");
        // console.log(`The href is: ${href}`);
      }
    }
  };

  // Check session storage after showing MyDisclaimerModal
  useEffect(() => {
    // const doNotShowModal = sessionStorage.getItem("doNotShowDisclaimerModal");
    // console.log("doNotShowModal", doNotShowModal);
    const doNotShow =
      sessionStorage.getItem("doNotShowDisclaimerModal") === "true";
    // console.log("doNotShow", doNotShow);
    if (doNotShow) {
      setDoNotShowAgain(true);
    }
  }, [showDisclaimerModal]);

  return (
    <>
      <div className="p-3" style={{color: "LightGray"}}>
        <span style={{fontWeight: "bold"}}>{referencesTitle}:</span>
        {references.map((reference, i) => {
          // let title = reference.title + " > " + reference.heading;
          // if (reference.heading === "Summary") {
          //   title = reference.title;
          // }
          let title = reference.title;
          title = similarityIcon(reference.similarity) + " " + title;
          return (
            <span key={i} style={{fontSize: "0.8rem", color: "LightGray"}}>
              <NavLink
                href={reference.url}
                target="_blank"
                onClick={(event) => handleShow(reference.url, event)}
              >
                - {title} <FontAwesomeIcon icon={faExternalLink} size="xs" />
              </NavLink>{" "}
            </span>
          );
        })}
      </div>
      <MyDisclaimerModal
        show={showDisclaimerModal}
        onHide={() => handleClose()}
      />
    </>
  );
};

export default MyReferences;
