import {
  Container,
  Navbar,
  Image,
  OverlayTrigger,
  Tooltip,
  NavLink,
  Button,
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket} from "@fortawesome/free-solid-svg-icons";
// import {FaAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";
import {useAuthenticator} from "@aws-amplify/ui-react";
import MyLanguageSwitcher from "./MyLanguageSwitcher";
import {useIntl} from "react-intl";


const MyNavbar = ({locale, onLanguageChange}) => {
  const intl = useIntl();

  const logoutToolTip = intl.formatMessage({id: "app.logoutToolTip"});

  const {signOut, user} = useAuthenticator((context) => [context.user]);
  // console.log(user);

  return (
    <>
      <div className="pt-5">{/* fixed-top correction */}</div>
      <Navbar
        bg="dark"
        variant="dark"
        expand="false"
        className="mb-0 py-0 navbar-border fixed-top"
        style={{
          borderBottom: "solid",
          borderTop: "solid",
          borderWidth: "thin",
          borderColor: "Black",
        }}
      >
        <Container fluid>
          <Navbar.Brand>
            {" "}
            <Image
              alt="Pega Enablement"
              src="./images/pega-enablement.png"
              width="30"
              height="30"
              className="d-inline-block align-top border"
              roundedCircle
            />{" "}
            <span className="mb-0 h1 fs-6">&nbsp; Pega Enablement</span>
          </Navbar.Brand>
          <div className="d-flex justify-content-end">
            <div
              style={{
                marginTop: "0.25rem",
                marginLeft: "-0.75rem",
                marginRight: "0.25rem",
              }}
            >
              {" "}
              <MyLanguageSwitcher
                locale={locale}
                onLanguageChange={onLanguageChange}
              >
                {" "}
              </MyLanguageSwitcher>
            </div>
            {/* Only render if user is logged in */}
            {user && (
              <div id="log-out">
                <OverlayTrigger
                  placement="bottom"
                  delay={{show: 500}}
                  overlay={
                    <Tooltip id="button-tooltip-2">{logoutToolTip}</Tooltip>
                  }
                >
                  <Button
                    variant="link"
                    className="m-1 border"
                    style={{
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      paddingTop: "0.15rem",
                    }}
                    onClick={signOut}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRightFromBracket}
                      style={{
                        color: "whitesmoke",
                        fontSize: "1rem",
                        marginLeft: "-5px",
                        marginTop: "-10px",
                      }}
                    />
                  </Button>
                </OverlayTrigger>{" "}
              </div>
            )}
            {user && (
              <div
                style={{
                  borderLeft: "1px solid grey",
                  paddingRight: "0.25rem",
                  marginLeft: "0.25rem",
                }}
              >
                {" "}
              </div>
            )}
            <OverlayTrigger
              placement="bottom"
              delay={{show: 500}}
              overlay={
                <Tooltip id="button-tooltip-2">Guru Deshkulkarni</Tooltip>
              }
            >
              <NavLink
                href={
                  "https://www.linkedin.com/in/gururaj-deshkulkarni-7aa13b19"
                }
                target="_blank"
              >
                <Image
                  className="m-1 border"
                  src="./images/guru-deshkulkarni.jpg"
                  alt="Guru Deshkulkarni"
                  width="30px"
                  height="auto"
                  roundedCircle
                  style={{}}
                />
              </NavLink>
            </OverlayTrigger>{" "}
            <OverlayTrigger
              placement="bottom"
              delay={{show: 500}}
              overlay={<Tooltip id="button-tooltip-2">Marco Looy</Tooltip>}
            >
              <NavLink
                href={"https://www.linkedin.com/in/marcolooy"}
                target="_blank"
              >
                <Image
                  className="border"
                  src="./images/marco-looy.jpg"
                  alt="Marco Looy"
                  width="30px"
                  height="auto"
                  roundedCircle
                  style={{
                    marginTop: "0.25rem",
                    marginLeft: "-0.75rem",
                  }}
                />
              </NavLink>
            </OverlayTrigger>{" "}
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default MyNavbar;
