import {Image} from "react-bootstrap";
import {useIntl} from "react-intl";

const MyHeader = () => {
  const intl = useIntl();

  const subTitle = intl.formatMessage({id: "app.subTitle"});

  return (
    <div className="pt-4 text-center " style={{color: "white"}}>
      <Image
        src="./images/pega-logo-horizontal-white.png"
        rounded
        style={{width: "11rem"}}
        className="pb-2"
      />
      <h2 className="mb-3">Self-study Buddy</h2>
      <span style={{fontSize: "1.0rem", color: "LightGray"}}>
        <span className="mobile-screen-only">
          {subTitle}
        </span>
        <span className="desktop-screen-only">
          {subTitle}
        </span>
      </span>
    </div>
  );
};

export default MyHeader;
