import {useState, useRef, useEffect} from "react";
import {Container, Col, Row} from "react-bootstrap";
import {useIntl} from "react-intl";


const MyFooter = () => {
  // BEGIN

  const intl = useIntl();

  const disclaimerTitle = intl.formatMessage({id: "app.disclaimerTitle"});
  const disclaimerDesktop = intl.formatMessage({id: "app.disclaimerDesktop"});
  const disclaimerMobile = intl.formatMessage({id: "app.disclaimerMobile"});

  // State
  const [fixedBottomCorrection, setFixedBottomCorrection] = useState("0px");

  // Refs
  const refFooter = useRef(null);

  // Handle onResize event
  const onResize = () => {
    // Get footer element
    const footer = refFooter.current;

    // Get footer size
    const offsetHeight = footer.offsetHeight;
    // console.log("TEST footer.offsetHeight: " + offsetHeight);

    // set fixed-bottom correction size
    setFixedBottomCorrection(offsetHeight + "px");
  };

  // Trigger onResize when component loaded
  useEffect(() => {
    // BEGIN

    onResize();

    // END
  }, []);

  // Monitor resizing of window
  useEffect(() => {
    // BEGIN

    // Add event listener
    window.addEventListener("resize", onResize);
    // console.log("addEventListener");

    // Remove event listener when component is unloaded
    return () => {
      window.removeEventListener("resize", onResize);
    };

    // END
  });

  return (
    <>
      <div style={{height: fixedBottomCorrection}}>
        {/* fixed-bottom correction */}
      </div>
      <footer ref={refFooter} className="fixed-bottom">
        <Container
          fluid
          className="mb-0 py-0 navbar-border bg-dark"
          style={{
            borderBottom: "solid",
            borderTop: "solid",
            borderWidth: "thin",
            borderColor: "Black",
          }}
        >
          <Row className="pt-1 pb-2">
            <Col></Col>
            <Col className="text-center" xs={10}>
              <span style={{fontSize: "0.8rem", color: "LightGray"}}>
                <span className="desktop-screen-only">
                  <b>{disclaimerTitle}:</b> {disclaimerDesktop}
                </span>
                <span className="mobile-screen-only">
                  <b>{disclaimerTitle}:</b> {disclaimerMobile}
                </span>
              </span>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default MyFooter;
