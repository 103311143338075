import {useState, useEffect, useRef} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faGlobe} from "@fortawesome/free-solid-svg-icons";

import "/node_modules/flag-icons/css/flag-icons.min.css";
import "./MyLanguageSwitcher.css";

// import MyDisclaimerModal from "./MyDisclaimerModal";

// Language options for the dropdown
const languageOptions = [
  {code: "en", name: "English", flag: "us"},
  {code: "fr", name: "Français", flag: "fr"},
  {code: "de", name: "Deutsch", flag: "de"},
  {code: "it", name: "Italiano", flag: "it"},
  {code: "ja", name: "日本語", flag: "jp"},
  {code: "pt", name: "Português", flag: "pt"},
  {code: "es", name: "Español", flag: "es"},
  {code: "pl", name: "Polski", flag: "pl"},
  {code: "nl", name: "Nederlands", flag: "nl"},
  {code: "ar", name: "العربية", flag: "sa"}, // You can choose "eg", "sa", "ar" or another as appropriate
  // ... add more language options here
];

const MyLanguageSwitcher = ({locale, onLanguageChange}) => {
  // const [disclaimerModalShow, setDisclaimerModalShow] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // This effect will set up the event listener and clean up when the component unmounts
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleLanguageChange = (code) => {
    onLanguageChange(code); // Call the prop function to notify App.js
    setDropdownOpen(false);
    // setDisclaimerModalShow(true);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const selectedLanguage = languageOptions.find(
    (option) => option.code === locale
  );

  // Render the button with or without the tooltip based on the showTooltip state
  const renderButton = () => (
    <button
      onClick={toggleDropdown}
      className="language-switcher-button"
      style={{
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        paddingTop: "0.15rem",
        borderColor: "transparent",
      }}
    >
      {/* <FontAwesomeIcon icon={faGlobe} className="language-icon" /> */}
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="language-icon"
        style={{width: "30px", height: "30px"}}
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="2" y1="12" x2="22" y2="12"></line>
        <path d="M12 2a15.3 15.3 0 0 0 0 20"></path>
        <path d="M12 22a15.3 15.3 0 0 0 0-20"></path>
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="whitesmoke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="language-icon"
        style={{
          width: "34px",
          height: "34px",
          paddingTop: "0rem",
          marginTop: "-0.3rem",
          marginLeft: "-0.2rem",
        }}
      >
        <circle cx="12" cy="12" r="10" strokeWidth="1"></circle>
        <line x1="2" y1="12" x2="22" y2="12" strokeWidth="1"></line>
        <path d="M3 8a20 8 0 0 1 18 0" strokeWidth="1"></path>
        <path d="M3 16a20 8 0 0 0 18 0" strokeWidth="1"></path>
        <line x1="12" y1="2" x2="12" y2="22" strokeWidth="1"></line>
        <path d="M12 2a13 13 0 0 0 0 20" strokeWidth="1"></path>
        <path d="M12 22a13 13 0 0 0 0-20" strokeWidth="1"></path>
      </svg>
    </button>
  );

  return (
    <>
      {/* <MyDisclaimerModal
        show={disclaimerModalShow}
        onHide={() => setDisclaimerModalShow(false)}
      /> */}
      <div className="language-switcher">
        {!dropdownOpen ? (
          <OverlayTrigger
            placement="bottom"
            delay={{show: 500}}
            overlay={
              <Tooltip id="button-tooltip-2">{selectedLanguage.name}</Tooltip>
            }
          >
            {renderButton()}
          </OverlayTrigger>
        ) : (
          renderButton()
        )}
        {dropdownOpen && (
          <div className="language-switcher-dropdown" ref={dropdownRef}>
            {" "}
            {/* {languageOptions.map((option) => ( */}
            {languageOptions
              // .filter((option) => option.code !== locale) // Filter out the currently selected language
              .map((option) => (
                <button
                  key={option.code}
                  onClick={() => handleLanguageChange(option.code)}
                  className={`language-switcher-option ${
                    locale === option.code ? "selected" : ""
                  }`}
                >
                  <span>{option.name}</span>
                  {locale === option.code && (
                    <span className="checkmark"></span>
                  )}
                </button>
              ))}
          </div>
        )}{" "}
      </div>
    </>
  );
};

export default MyLanguageSwitcher;
