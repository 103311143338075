import {Image} from "react-bootstrap";

// Custom components for the authenticator
const authenticatorComponents = {
  Header() {
    return (
      <div className="pt-4 text-center " style={{color: "white"}}>
        <Image
          src="./images/pega-logo-horizontal-white.png"
          rounded
          style={{width: "11rem"}}
          className="pb-2"
        />
        <h2 className="mb-3">Self-study Buddy</h2>
      </div>
    );
  },
};

// Form fields for the sign in and sign up pages
const authenticatorFormFields = {
  signIn: {
    username: {
      label: "",
      placeholder: "Enter your email",
    },
    password: {
      label: "",
      placeholder: "Enter your password",
    },
  },
  signUp: {
    given_name: {
      order: 1,
      label: "",
      placeholder: "Enter your first name",
    },
    family_name: {
      order: 2,
      label: "",
      placeholder: "Enter your last name",
    },
    email: {
      order: 3,
      label: "",
      placeholder: "Enter your email",
    },
    password: {
      order: 5,
      label: "",
      placeholder: "Enter your password",
    },
    confirm_password: {
      order: 6,
      label: "",
      placeholder: "Confirm your password",
    },
  },
};

export {authenticatorComponents, authenticatorFormFields};
