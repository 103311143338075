import "./App.css";
// import styles from "./index.module.css";
import {useState, useEffect, useRef} from "react";
import {Helmet} from "react-helmet";
import {Row, Col, Form, Button, Container, Spinner} from "react-bootstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {IntlProvider} from "react-intl";
// import {FiClipboard} from "react-icons/fi";
import {FaCopy, FaThumbsUp, FaThumbsDown} from "react-icons/fa";

import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// http://ericgio.github.io/react-bootstrap-typeahead/
// import {Typeahead} from "react-bootstrap-typeahead";
// https://www.npmjs.com/package/react-select
import Select from "react-select";
import MyNavbar from "./components/MyNavbar";
import MyHeader from "./components/MyHeader";
// import MyAcademyBadges from "./components/MyAcademyBadges";
// import MyDocsBadges from "./components/MyDocsBadges";
import MyFooter from "./components/MyFooter";
// import questions from "./data/questions.json";
// import MyVideo from "./components/MyVideo";
// import MyGrammarly from "./components/MyGrammarly";
import Session from "react-session-api";
import uuid from "react-uuid";
import MyReferences from "./components/MyReferences";
// import Markdown from "markdown-to-jsx";
import MyBackground from "./components/MyBackground";
// import MyLanguageSwitcher from "./components/MyLanguageSwitcher";

// import MyTestComponent from "./components/MyTestComponent";

// aws-amplify
import {I18n, Auth} from "aws-amplify";
import {Authenticator} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  authenticatorComponents,
  authenticatorFormFields,
} from "./components/MyAuthenticator";

// aws-amplify
I18n.putVocabulariesForLanguage("en", {
  "Create Account": "Register", // Tab header
  "Sign In": "Login", // Tab header
  "Sign in": "Login", // Button label
  "Signing in": "Logging in", // Button label"
  "Back to Sign In": "Back to Login", // Reset password page
});

// Messages for different languages
import messages from "./data/messages";

// function App({signOut, user}) {
function App() {
  // BEGIN

  const questionRef = useRef(null);
  const answerRef = useRef(null);

  // State
  // Counter state
  const [counter, setCounter] = useState(0);
  // const [counterInterval, setCounterInterval] = useState(null);
  const counterIntervalRef = useRef(null);

  // const [newQuestion, setNewQuestion] = useState("");
  const [question, setQuestion] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [answer, setAnswer] = useState("");
  const [previousQuestion, setPreviousQuestion] = useState("");
  // const [newAnswer, setNewAnswer] = useState("");
  const [references, setReferences] = useState([]);
  const [newReferences, setNewReferences] = useState([]);
  const [animatedAnswer, setAnimatedAnswer] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  // const [videoState, setVideoState] = useState("play");
  // const [videoName, setVideoName] = useState("intro");
  // const [disableQuestion, setDisableQuestion] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [backgroundAnimationState, setBackgroundAnimationState] =
    useState("pause");

  // react-select
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  // TODO
  // eslint-disable-next-line no-unused-vars
  // const [cognitoUser, setCognitoUser] = useState(user);
  // const [signMeOut, setSignMeOut] = useState(null);

  // ip data
  // const [ip, setIp] = useState("");
  // const ipSet = useRef(false);
  const [sessionUuid, setSessionUuid] = useState("");
  const sessionUuidSet = useRef(false);

  // Locale
  const [locale, setLocale] = useState(
    navigator.language.split("-")[0] || "en"
  );
  const [currentMessages, setCurrentMessages] = useState(messages[locale]);

  // Copy to clipboard
  const [isCopyClicked, setIsCopyClicked] = useState(false);
  const [isThumbsUpClicked, setIsThumbsUpClicked] = useState(false);
  const [isThumbsDownClicked, setIsThumbsDownClicked] = useState(false);

  useEffect(() => {
    setCurrentMessages(messages[locale]);
  }, [locale]);

  const handleLanguageChange = (newLocale) => {
    // console.log(messages[newLocale]);
    setLocale(newLocale);
    setQuestion("");
    setAnswer("");
    setReferences([]);
  };

  // Function to start the counter
  const startCounter = () => {
    if (!counterIntervalRef.current) {
      counterIntervalRef.current = setInterval(() => {
        setCounter((prevCounter) => Math.round((prevCounter + 0.1) * 10) / 10);
      }, 100);
    }
  };
  //  const startCounter = () => {
  //   if (!counterInterval) {
  //     const intervalId = setInterval(() => {
  //       setCounter((prevCounter) => Math.round((prevCounter + 0.1) * 10) / 10);
  //     }, 100);
  //     setCounterInterval(intervalId);
  //   }
  // };

  // Function to stop the counter
  const stopCounter = () => {
    if (counterIntervalRef.current) {
      clearInterval(counterIntervalRef.current);
      counterIntervalRef.current = null;
    }
  };
  // const stopCounter = () => {
  //   if (counterInterval) {
  //     clearInterval(counterInterval);
  //     setCounterInterval(null);
  //   }
  // };

  // Effect to start/stop the counter based on the disableButton state
  useEffect(() => {
    if (backgroundAnimationState === "resume") {
      setCounter(0);
      startCounter();
    } else if (backgroundAnimationState === "pause") {
      stopCounter();
    }

    // Cleanup on unmount
    return () => {
      if (counterIntervalRef.current) {
        clearInterval(counterIntervalRef.current);
      }
    };
  }, [backgroundAnimationState]);

  // Get ip just once when the page is rendered
  // useEffect(() => {
  //   // BEGIN

  //   // If ip already set
  //   if (ipSet.current) return;
  //   ipSet.current = true;

  //   async function fetchData() {
  //     // Connect to https://ipapi.co with fetch()
  //     const response = await fetch("https://ipapi.co/json/");
  //     const data = await response.json();

  //     // Set the IP address to the constant `ip`
  //     setIp(data.ip);
  //   }

  //   // If we do not already the ip number
  //   if (ip.length < 1) {
  //     fetchData();
  //   }

  //   // END
  // }, []);

  // Set session id once when the page is rendered
  useEffect(() => {
    // BEGIN

    // If sessionUuid already set
    if (sessionUuidSet.current) return;
    sessionUuidSet.current = true;

    // If we do not already have the uuid
    if (sessionUuid.length < 1) {
      // Get session uuid
      let sessionUuid = Session.get("uuid");

      // If there is none
      if (!sessionUuid) {
        // Create new uuid
        sessionUuid = uuid();

        // Store uuid in session
        Session.set("uuid", sessionUuid);
      }

      setSessionUuid(sessionUuid);

      // console.log("sessionUuid: " + sessionUuid);
    }

    // END
  }, []);

  // Typeahead
  // const [singleSelections, setSingleSelections] = useState([]);
  // // eslint-disable-next-line no-unused-vars
  // const [options, setOptions] = useState(["What is Pega Customer Decision Hub?","What is Pega Customer Service?","What is Pega Sales Automation?"]);

  // Handle ask
  async function handleAsk(event) {
    event.preventDefault();

    // Disable button
    setDisableButton(true);
    // setVideoName("loop");
    // setVideoState("play");
    // setDisableQuestion(true);

    // Show spinner
    setShowSpinner(true);
    setBackgroundAnimationState("resume");
    // startCounter();

    // Clear previous answer
    const initialReply = currentMessages["app.initialReply"];

    setAnswer(initialReply);
    setReferences([]);
    setNewReferences([]);
    setAnimatedAnswer;
    ("");
    setQuestionId(null);

    // console.log("onSubmit");

    let newAnswer = currentMessages["app.newAnswer"];

    // Get answer
    try {
      // BEGIN

      // setQuestion(newQuestion);
      // setNewQuestion("");
      // console.log("newQuestion: " + JSON.stringify({newQuestion: newQuestion}));
      // console.log("question: " + JSON.stringify({question: question}));

      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      // console.log("token: ", token);

      // https://v3xion23s8.execute-api.us-east-1.amazonaws.com/dev/items
      // // BEGIN test
      // try {
      //   const testUrl = "https://api.pegaenablement.com/self-study-buddy";
      //   const testRequest = {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: token,
      //     },
      //   };
      //   const testResponse = await fetch(testUrl, testRequest);
      //   if (testResponse.status !== 200) {
      //     console.log(
      //       data.error ||
      //         new Error(`Request failed with status ${testResponse.status}`)
      //     );
      //   }
      //   const testData = await testResponse.json();
      //   console.log("testData: " + JSON.stringify(testData));
      // } catch (error) {
      //   console.log(error);
      // }
      // END test

      // ################################################################################
      // The API Gateway endpoint is implemented using Amplify backend "selfstudybuddyapi7b3dd5aa" that is part of this project
      // The endpoint delegates the request to Lambda function "selfstudybuddyappc097ac2e" that is also part of this project
      // Hence you can find Lambda function code in "amplify/backend/function/selfstudybuddyappc097ac2e/src/index.js"
      // ################################################################################

      // eslint-disable-next-line no-undef
      const apiUrl = process.env.REACT_APP_API_URL;

      const action = "askQuestion";

      const identity = "self-study-buddy";
      // const identity = "sales-buddy";

      // Remove leading and trailing whitespace
      const trimmedQuestion = question.trim();

      // Keeping a record of previous question
      setPreviousQuestion(question);

      // console.log("API URL: " + apiUrl);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          action: action,
          identity: identity,
          question: trimmedQuestion,
          locale: locale,
        }),
      });

      const data = await response.json();
      // console.log("data: " + JSON.stringify(data));

      newAnswer = data["answer"];
      const references = data["references"];
      setNewReferences(references);

      // Storing questionId so we can associate future feedback with the question.
      setQuestionId(data["questionID"]);

      if (response.status !== 200) {
        throw (
          data.error ||
          new Error(`Request failed with status ${response.status}`)
        );
      }
    } catch (error) {
      // Consider implementing your own error handling logic here
      // console.error(error);
      // alert(error.message);
      console.log(error.message);
      await sleep(1000);
    }

    // Hide spinner
    setShowSpinner(false);
    setBackgroundAnimationState("pause");
    // stopCounter();
    // setVideoState("stop");

    // Set answer
    // answer = data["answer"];
    setAnswer(newAnswer);

    // console.log("answer: " + JSON.stringify({answer: answer}));

    // // Enable question
    // setDisableButton(false);
    // setDisableQuestion(false);

    // END
  }

  // Print references
  useEffect(() => {
    // console.log("References: ", references);
  }, [references]);

  // On question select event
  const onQuestionSelect = (question) => {
    setSelectedQuestion("");
    // const question = e.label;
    // console.log(question);
    if (question) {
      setQuestion(question["label"]);
    }
  };

  const sleep = async (milliseconds) => {
    await new Promise((resolve) => {
      return setTimeout(resolve, milliseconds);
    });
  };

  // Disable button if there is no question
  useEffect(() => {
    // console.log("previousQuestion: [" + previousQuestion.trim() + "]");
    // console.log("question: [" + question.trim() + "]");

    // Set button state
    const trimmedQuestion = question.trim();
    // Check against previous question to avoid asking the same question twice
    if (previousQuestion.trim() === question.trim()) {
      // console.log("equal");
      // setShowSpinner(false);
      if (!disableButton) {
        setDisableButton(true);
      }
      // setDisableButton(true);
    } else if (trimmedQuestion.length < 1) {
      // if (question.length < 1) {
      // console.log("question empty");
      // setShowSpinner(false);
      if (!disableButton) {
        setDisableButton(true);
      }
      // setDisableButton(true);
      // setVideoState("stop");
      // setDisableQuestion(false);
    } else {
      // console.log("valid question");
      // setShowSpinner(false);
      if (disableButton) {
        setDisableButton(false);
      }
      // setDisableButton(false);
      // setVideoName("loop");
      // setVideoState("play");
      // setDisableQuestion(true);
    }
    // console.log("button disabled: " + disableButton);
  }, [question, disableButton]);

  // Type answer
  useEffect(() => {
    // setAnimatedAnswer(answer);
    if (answer.length !== animatedAnswer.length) {
      // Disable question while answering
      // setDisableQuestion(false);

      const timeout = setTimeout(() => {
        // setText1(first_text.slice(0, text1.length + 1));
        const animatedAnswerPartial = answer.slice(
          0,
          animatedAnswer.length + 1
        );
        setAnimatedAnswer(animatedAnswerPartial);
      }, 5);

      return () => clearTimeout(timeout);
    }

    // When done typing answer, we enable the button
    if (answer && animatedAnswer) {
      if (answer.length === animatedAnswer.length) {
        // if (disableButton) {
        //   if (question.length > 0) {
        //     // Enable button
        //     setDisableButton(false);
        //     // setDisableQuestion(false);
        //   }
        // }
        setReferences(newReferences);
      }
    }
  }, [question, answer, animatedAnswer, disableButton, newReferences]);

  // Handle question change
  function handleQuestionChange(event) {
    setQuestion(event.target.value);
  }

  // Autosize question area
  useEffect(() => {
    if (questionRef.current) {
      questionRef.current.style.height = "auto";
      questionRef.current.style.height = `${
        questionRef.current.scrollHeight + 2
      }px`;
    }
  }, [question]);

  // Handle answer change
  // function handleAnswerChange(event) {
  //   setNewAnswer(event.target.value);
  // }

  // Autosize answer area
  useEffect(() => {
    if (answerRef.current) {
      answerRef.current.style.height = "auto";
      answerRef.current.style.height = `${
        answerRef.current.scrollHeight + 2
      }px`;
    }
  }, [animatedAnswer]);

  // Function that copies text to the clipboard
  const handleCopyClicked = () => {
    // Copy question and answer
    const text = question + "\n\n" + answer + "\n\n";

    // Trigger the click effect
    setIsCopyClicked(true);

    // Copy to clipboard
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Success feedback
        toast(
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FaCopy style={{marginRight: "8px"}} />
            <span>{currentMessages["app.copyText"]}</span>
          </div>
        );
      })
      .catch(() => {
        // Error feedback
        // console.error("Failed to copy text: ", err);
        // toast.error("Failed to copy!");
      });

    // Remove the click effect after a short delay
    setTimeout(() => setIsCopyClicked(false), 200);
  };

  // Give feedback
  async function giveFeedback(feedback) {
    try {
      // BEGIN

      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      // ################################################################################
      // The API Gateway endpoint is implemented using Amplify backend "selfstudybuddyapi7b3dd5aa" that is part of this project
      // The endpoint delegates the request to Lambda function "selfstudybuddyappc097ac2e" that is also part of this project
      // Hence you can find Lambda function code in "amplify/backend/function/selfstudybuddyappc097ac2e/src/index.js"
      // ################################################################################

      // eslint-disable-next-line no-undef
      const apiUrl = process.env.REACT_APP_API_URL;

      const action = "giveFeedback";
      const questionID = questionId;
      const helpful = feedback["helpful"];
      const comments = "No comments";

      // Make call
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          action: action,
          questionID: questionID,
          helpful: helpful,
          comments: comments,
        }),
      });

      const data = await response.json();
      console.log("data: " + JSON.stringify(data));

      if (response.status !== 200) {
        throw (
          data.error ||
          new Error(`Request failed with status ${response.status}`)
        );
      }
    } catch (error) {
      // Consider implementing your own error handling logic here
      // console.error(error);
      // alert(error.message);
      console.log(error.message);
      await sleep(1000);
    }
  }

  // Function that handles Thumbs up
  const handleThumbsUpClicked = async () => {
    // Trigger the click effect
    setIsThumbsUpClicked(true);

    // toast.success("Thanks for the feedback!");
    toast(
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FaThumbsUp style={{marginRight: "8px"}} />
        <span>{currentMessages["app.thumbsUpText"]}</span>
      </div>
    );

    // Remove the click effect after a short delay
    setTimeout(() => setIsThumbsUpClicked(false), 200);

    // Send feedback
    await giveFeedback({
      helpful: "Yes",
    });
  };

  // Function that handles Thumbs down
  const handleThumbsDownClicked = async () => {
    // Trigger the click effect
    setIsThumbsDownClicked(true);

    // toast.success("Thanks for the feedback!");
    toast(
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FaThumbsDown style={{marginRight: "8px"}} />
        <span>{currentMessages["app.thumbsDownText"]}</span>
      </div>
    );

    // Remove the click effect after a short delay
    setTimeout(() => setIsThumbsDownClicked(false), 200);

    // Send feedback
    await giveFeedback({
      helpful: "No",
    });
  };

  return (
    <>
      <IntlProvider locale={locale} messages={currentMessages}>
        <MyBackground state={backgroundAnimationState}></MyBackground>
        <Helmet
          bodyAttributes={{
            style: "background-color:#181818",
          }}
        ></Helmet>
        {/* <MyVideo name={"intro"} state={"play"}></MyVideo> */}

        <MyNavbar
          locale={locale}
          onLanguageChange={handleLanguageChange}
        ></MyNavbar>

        <Authenticator
          variation="modal"
          components={authenticatorComponents}
          formFields={authenticatorFormFields}
        >
          {() => (
            <>
              <ToastContainer
                position="top-center"
                theme="dark"
                pauseOnHover={false}
                pauseOnFocusLoss={false}
                autoClose={3000}
                closeButton={false}
                hideProgressBar={true}
              />
              <Container fluid style={{maxWidth: "800px", margin: "0 auto"}}>
                {/* <Row className=""> */}
                {/* <Col><MyAcademyBadges></MyAcademyBadges></Col> */}
                {/* <Col xs={8}> */}
                <MyHeader></MyHeader>
                {/* <MyTestComponent></MyTestComponent> */}
                {/* <MyLanguageSwitcher> </MyLanguageSwitcher> */}
                <Form onSubmit={handleAsk} className="pt-3">
                  <Form.Group className="mb-3">
                    <Select
                      className="pb-2"
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: "#DCDCDC",
                        }),
                      }}
                      defaultValue={selectedQuestion}
                      value={selectedQuestion}
                      onChange={(e) => {
                        onQuestionSelect(e);
                      }}
                      options={currentMessages["questions"]}
                      isDisabled={false}
                      isClearable={true}
                      placeholder={currentMessages["app.sampleQuestions"]}
                      noOptionsMessage={() => "No matches found."}
                    />
                    {/* <MyGrammarly> */}
                    <Form.Control
                      as="textarea"
                      placeholder={currentMessages["app.ownQuestion"]}
                      // style={{height: "100px"}}
                      disabled={false}
                      value={question}
                      // onChange={(e) => setQuestion(e.target.value)}
                      onChange={handleQuestionChange}
                      rows={3}
                      ref={questionRef}
                      id="question"
                    />
                    {/* </MyGrammarly> */}
                  </Form.Group>
                  <Row className="pt-2 justify-content-center">
                    <Col xs={4} md={4} className="text-left align-self-center">
                      <span
                        style={{
                          fontSize: "1.0rem",
                          color: "Gray",
                          paddingLeft: "12px",
                        }}
                      >
                        {disableButton && counter > 0
                          ? `${counter.toFixed(1)}s`
                          : ""}
                      </span>
                    </Col>
                    <Col xs={4} md={4}>
                      <div className="text-center">
                        <Button
                          variant="outline-dark"
                          type="submit"
                          disabled={disableButton}
                          style={{
                            height: "40px",
                            width: "100px",
                            color: "#151619",
                            backgroundColor: "#FFC836",
                            fontWeight: "bold",
                          }}
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={
                              showSpinner ? {display: ""} : {display: "none"}
                            }
                          />
                          <span
                            style={
                              !showSpinner ? {display: ""} : {display: "none"}
                            }
                          >
                            {currentMessages["app.askButtonLabel"]}
                          </span>
                        </Button>
                      </div>
                    </Col>
                    <Col xs={4} md={4} className="text-right align-self-center">
                      {questionId && (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{show: 500}}
                            overlay={
                              <Tooltip id="copy-tooltip">
                                {isCopyClicked
                                  ? ""
                                  : currentMessages["app.copyButton"]}
                              </Tooltip>
                            }
                          >
                            <span
                              style={{
                                fontSize: "1.0rem",
                                color: "Gray",
                                paddingRight: "12px",
                                float: "right",
                                cursor: "pointer",
                              }}
                              className={
                                isCopyClicked
                                  ? "copy-icon-clicked"
                                  : "copy-icon"
                              }
                            >
                              <FaCopy
                                size={20}
                                onClick={() => handleCopyClicked()}
                              />
                            </span>
                          </OverlayTrigger>{" "}
                          <OverlayTrigger
                            placement="bottom"
                            delay={{show: 500}}
                            overlay={
                              <Tooltip id="thumbs-down-tooltip">
                                {isThumbsDownClicked
                                  ? ""
                                  : currentMessages["app.thumbsDownButton"]}
                              </Tooltip>
                            }
                          >
                            <span
                              style={{
                                fontSize: "1.0rem",
                                color: "Gray",
                                paddingRight: "12px",
                                float: "right",
                                cursor: "pointer",
                              }}
                              className={
                                isThumbsDownClicked
                                  ? "thumbs-down-icon-clicked"
                                  : "thumbs-down-icon"
                              }
                            >
                              <FaThumbsDown
                                size={20}
                                onClick={() => handleThumbsDownClicked()}
                              />
                            </span>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{show: 500}}
                            overlay={
                              <Tooltip id="thumbs-up-tooltip">
                                {isThumbsUpClicked
                                  ? ""
                                  : currentMessages["app.thumbsUpButton"]}
                              </Tooltip>
                            }
                          >
                            <span
                              style={{
                                fontSize: "1.0rem",
                                color: "Gray",
                                paddingRight: "12px",
                                float: "right",
                                cursor: "pointer",
                              }}
                              className={
                                isThumbsUpClicked
                                  ? "thumbs-up-icon-clicked"
                                  : "thumbs-up-icon"
                              }
                            >
                              <FaThumbsUp
                                size={20}
                                onClick={() => handleThumbsUpClicked()}
                              />
                            </span>
                          </OverlayTrigger>{" "}
                        </>
                      )}
                    </Col>
                  </Row>{" "}
                </Form>
                <Form className="pt-3">
                  <Form.Group className="mb-3">
                    <Form.Control
                      as="textarea"
                      readOnly
                      // style={{height: "300px", backgroundColor: "#DCDCDC"}}
                      style={{backgroundColor: "#DCDCDC"}}
                      // value={animatedAnswer}
                      defaultValue={animatedAnswer}
                      // onChange={handleAnswerChange}
                      rows={6}
                      ref={answerRef}
                      id="answer"
                    />{" "}
                    {/* <Form.Text
                      as="div"
                      style={{height: "220px", backgroundColor: "#DCDCDC"}}
                      className="form-control"
                    >
                      <Markdown options={{wrapper: "article"}}>
                        {animatedAnswer}
                      </Markdown>
                    </Form.Text> */}
                    <MyReferences references={references}></MyReferences>
                  </Form.Group>
                </Form>
                {/* </Col> */}
                {/* <Col><MyDocsBadges></MyDocsBadges></Col> */}
                {/* </Row> */}
              </Container>
            </>
          )}
        </Authenticator>

        {/* Footer */}
        <MyFooter></MyFooter>
      </IntlProvider>
    </>
  );

  // END
}

export default App;
