import "./MyBackground.css";
import {useState, useEffect, useRef} from "react";

const MyBackground = ({state}) => {
  // BEGIN

  // Ref for the background animation
  const backgroundTopLeftRef = useRef(null);
  const backgroundBottomRightRef = useRef(null);

  // State for the animation
  const [animationState, setAnimationState] = useState(state);
  const [backgroundTopLeftAnimation, setBackgroundTopLeftAnimation] =
    useState(null);
  const [backgroundBottomRightAnimation, setBackgroundBottomRightAnimation] =
    useState(null);

  // Set the state of the animation
  if (animationState != state) {
    setAnimationState(state);
  }

  useEffect(() => {
    // Pause the animations when the state changes
    if (animationState === "pause") {
      setBackgroundTopLeftAnimation(
        backgroundTopLeftRef.current.style.animation
      );
      backgroundTopLeftRef.current.style.animationPlayState = "paused";
      setBackgroundBottomRightAnimation(
        backgroundBottomRightRef.current.style.animation
      );
      backgroundBottomRightRef.current.style.animationPlayState = "paused";
    }

    // Resume the animations when the state changes
    if (animationState === "resume") {
      backgroundTopLeftRef.current.style.animation = backgroundTopLeftAnimation;
      backgroundTopLeftRef.current.style.animationPlayState = "running";
      backgroundBottomRightRef.current.style.animation =
        backgroundBottomRightAnimation;
      backgroundBottomRightRef.current.style.animationPlayState = "running";
    }
  }, [animationState]);

  return (
    <>
      <svg
        preserveAspectRatio="xMidYMid slice"
        viewBox="10 10 80 80"
        className="background-svg"
      >
        <defs></defs>
        <path
          className="background-top-left"
          d="M20.6,4.1C11.6,1.5-1.9,2.5-8,11.2-16.3,23.1-8.2,45.6,7.4,50S42.1,38.9,41,24.5C40.2,14.1,29.4,6.6,20.6,4.1Z"
          ref={backgroundTopLeftRef}
        />
        <path
          className="background-bottom-right"
          d="M105.9,48.6c-12.4-8.2-29.3-4.8-39.4.8-23.4,12.8-37.7,51.9-19.1,74.1s63.9,15.3,76-5.6c7.6-13.3,1.8-31.1-2.3-43.8C117.6,63.3,114.7,54.3,105.9,48.6Z"
          ref={backgroundBottomRightRef}
        />
      </svg>
    </>
  );

  // END
};

export default MyBackground;
