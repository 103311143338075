import {useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {useIntl} from "react-intl";
import "./MyDisclaimerModal.css";

function MyDisclaimerModal(props) {
  // console.log("props.show:", props.show);

  // Localization
  const intl = useIntl();
  const disclaimerModalTitle = intl.formatMessage({
    id: "app.disclaimerModalTitle",
  });
  const disclaimerModalText = intl.formatMessage({
    id: "app.disclaimerModalText",
  });
  const disclaimerModalButton = intl.formatMessage({
    id: "app.disclaimerModalButton",
  });
  const disclaimerModalCheckbox = intl.formatMessage({
    id: "app.disclaimerModalCheckbox",
  });

  // State for checkbox
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  // const [shouldShowModal, setShouldShowModal] = useState(true);

  // Check session storage on mount
  // useEffect(() => {
  //   const doNotShowModal = sessionStorage.getItem("doNotShowDisclaimerModal");
  //   console.log("doNotShowModal", doNotShowModal);
  //   const doNotShow =
  //     sessionStorage.getItem("doNotShowDisclaimerModal") === "true";
  //   if (doNotShow) {
  //     setShouldShowModal(false);
  //   }
  // }, []);

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setDoNotShowAgain(event.target.checked);
    sessionStorage.setItem(
      "doNotShowDisclaimerModal",
      event.target.checked ? "true" : "false"
    );
  };

  // Handle modal close
  const handleClose = () => {
    props.onHide();
  };

  // If shouldShowModal is false, do not render the modal
  // if (!shouldShowModal) {
  //   return null;
  // }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header className="dark-mode-header">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="dark-mode-title"
        >
          {disclaimerModalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode-body">
        <span style={{fontSize: "1.0rem", color: "#D3D3D3"}}>
          <p>{disclaimerModalText}</p>
        </span>
      </Modal.Body>
      <Modal.Footer className="dark-mode-footer" variant="dark">
        <div style={{flex: 1, display: "flex", alignItems: "center"}}>
          <input
            type="checkbox"
            id="doNotShowAgain"
            checked={doNotShowAgain}
            onChange={handleCheckboxChange}
          />
          <label
            htmlFor="doNotShowAgain"
            style={{marginLeft: "0.5rem", fontSize: "1.0rem", color: "#D3D3D3"}}
          >
            {disclaimerModalCheckbox}
          </label>
        </div>
        <Button onClick={handleClose} className="dark-mode-btn">
          {disclaimerModalButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyDisclaimerModal;
